<template>
  <div :style="themeCss">
    <div id="aw--c">
      <div id="aw-sl" style="font-size: 1em !important">
        <SeoJsonLdComponentVue :videoInfo="videoInfo" v-if="videoInfo != undefined" />
        <TheaterPlayer
          :videos="lightboxVideos"
          :logoOverlay="videoInfo.overlay_logo"
          :useDemoOverlay="videoInfo.use_demo_overlay"
          :themeCss="themeCss"
          :ma="videoInfo.ma"
          :cta="videoInfo.cta"
          :isGated="videoInfo.is_gated"
          :post_play="videoInfo.post_play"
          v-if="videoInfo && lightboxVideos && lightboxVideos.length > 0 && usetheaterMode == true"
        />
        <div v-if="videoInfo != undefined" :key="videoInfo.video_urls.dash_url" class="amplily-player">
          <MAGate :ma="videoInfo.ma" :themeCss="themeCss" />
          <div class="rnd-crns">
            <VideoPlayer
              :poster="videoInfo.video_urls.poster_url"
              :video_urls="videoInfo.video_urls"
              :mid="videoInfo.moment_id"
              :subtitleUrls="videoInfo.subtitle_urls"
              :subtitles="videoInfo.subtitles"
              :autoloadSubtitle="videoInfo.default_subtitle"
              :duration="videoInfo.duration"
              :videoType="videoType"
              :title="videoInfo.title"
              :useDemoOverlay="videoInfo.use_demo_overlay"
              :logoOverlay="videoInfo.overlay_logo"
              :isgated="videoInfo.is_gated"
              :ma="videoInfo.ma"
              :cta="videoInfo.cta"
              :usetheater-mode="usetheaterMode"
              v-if="!videoInfo.video_urls.iframe_url || videoInfo.video_urls.iframe_url == ''"
            />
            <IframeVideoPlayer
              v-else
              :iframe_url="videoInfo.video_urls.iframe_url"
              :poster="videoInfo.video_urls.poster_url"
              :duration="videoInfo.duration"
            />
          </div>
          <a :href="redirect_url" id="redirect_btn" v-if="redirect_url">
            <a v-html="redirectText"> </a
            ><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2.3926 4.31165C2.17186 4.07644 2.17186 3.71019 2.3926 3.47498C2.63407 3.21767 3.04264 3.21767 3.28411 3.47498L7.35778 7.81568C7.71887 8.20044 7.71887 8.79956 7.35778 9.18432L3.28412 13.525C3.04264 13.7823 2.63407 13.7823 2.3926 13.525C2.17186 13.2898 2.17186 12.9236 2.3926 12.6884L5.68106 9.18432C6.04216 8.79956 6.04216 8.20044 5.68106 7.81568L2.3926 4.31165Z"
                class="contrast"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.3926 4.31165C8.17186 4.07644 8.17186 3.71019 8.3926 3.47498C8.63407 3.21767 9.04264 3.21767 9.28411 3.47498L13.3578 7.81568C13.7189 8.20044 13.7189 8.79956 13.3578 9.18432L9.28412 13.525C9.04264 13.7823 8.63407 13.7823 8.3926 13.525C8.17186 13.2898 8.17186 12.9236 8.3926 12.6884L11.6811 9.18432C12.0422 8.79956 12.0422 8.20044 11.6811 7.81568L8.3926 4.31165Z"
                class="contrast"
              />
            </svg>
          </a>
        </div>
        <singleSkeleton :loadingmsg="loadingmsg" v-else />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import "@/assets/_base.scss"

import VideoPlayer from "../components/shared/VideoPlayer.vue"
import IframeVideoPlayer from "../components/shared/IframeVideoPlayer.vue"
import { SingleVideoInfo } from "../models/SingleVideoInfo"
import { AwEventType } from "../models/AwEventType"
import setThemeData from "../services/setThemeData"
import { GoogleAnalyticsClient } from "@/services/GoogleAnalyticsService"
import { AnalyticsClient } from "@/services/AnalyticsService"
import { WebinarDataService } from "@/services/WebinarDataServices"
import { container } from "tsyringe"
import { computed, defineComponent, ref } from "@vue/runtime-core"
import injectStrict from "@/utils/injectStrict"
import { GlobalDataKey, LoggerKey } from "@/symbols"
import getVideoType from "@/utils/getVideoType"
import { ContentType } from "@/models/ContentType"
import { AwEventStoreV2 } from "@/models/AwEventStoreV2"
import MAGate from "@/components/MA/MAGate.vue"
import getCssVariables from "@/utils/getCssVariables"
import SeoJsonLdComponentVue from "@/components/shared/SeoJsonLdComponent.vue"
import { LightboxVideo } from "@/models/lightbox/LightboxModels"
import TheaterPlayer from "@/components/shared/TheaterPlayer.vue"
import { isIOS } from "@/services/deviceDetection"
import singleSkeleton from "@/components/skeletons/single.vue"

export default defineComponent({
  components: { VideoPlayer, IframeVideoPlayer, MAGate, SeoJsonLdComponentVue, TheaterPlayer, singleSkeleton },
  props: {
    fetchId: { type: String, required: true }
  },
  setup(props) {
    const globalData = injectStrict(GlobalDataKey).value
    const logger = injectStrict(LoggerKey)
    const videoType: ContentType | undefined = getVideoType(props.fetchId)
    const id = ref(-1)
    const adId = ref(-1)
    const videoInfo = ref<SingleVideoInfo | null>(null)
    const loadingmsg = ref("")
    const widgetHeight = computed<number>(() => globalData.height)
    let redirect_url = computed(() => globalData.redirect_data.url)
    let redirectText = computed(() => globalData.redirect_data.text)
    const themeCss = computed(() => getCssVariables(globalData.theme_data))
    const dataService = container.resolve(WebinarDataService)
    const analytics = container.resolve(AnalyticsClient)
    const gaAnalytics = container.resolve(GoogleAnalyticsClient)

    let lightboxVideos = computed<Array<LightboxVideo>>(() => {
      let lgtboxmoments: Array<LightboxVideo> = []
      if (videoInfo.value) {
        lgtboxmoments.push(
          new LightboxVideo(
            videoInfo.value.moment_id ? parseInt(videoInfo.value.moment_id) : -1,
            videoInfo.value.video_urls,
            videoInfo.value.subtitles,
            videoInfo.value.duration,
            videoInfo.value.moment_id ? parseInt(videoInfo.value.moment_id) : -1,
            videoType ?? ContentType.Webinar,
            videoInfo.value.title,
            0
          )
        )
      }
      return lgtboxmoments
    })
    let usetheaterMode = computed(
      () =>
        videoInfo.value &&
        videoInfo.value.use_theater_mode == true &&
        !!window.parmonic_disable_theater_mode == false &&
        !isIOS(navigator.userAgent, navigator.maxTouchPoints)
    )

    return {
      lightboxVideos,
      dataService,
      widgetHeight,
      themeCss,
      globalData,
      id,
      gaAnalytics,
      videoInfo,
      loadingmsg,
      analytics,
      redirect_url,
      logger,
      redirectText,
      videoType,
      adId,
      usetheaterMode
    }
  },
  created() {
    this.dataService.getData(this.fetchId).then(returnValue => {
      this.loadingmsg = "The video could not be loaded."
      if (returnValue == undefined) {
        this.logger!.error(`Parmonic Widget error: Video not found`)
        return
      }
      this.videoInfo = returnValue as SingleVideoInfo
      this.adId = this.videoInfo.ad_id
      this.id = this.videoInfo.id
      setThemeData(this.videoInfo.theme_data, this.globalData)
      var webinarId = this.id > -1 && !(this.videoType == ContentType.Misc) ? this.id : null
      var miscId = this.id > -1 && this.videoType == ContentType.Misc ? this.id : null
      this.analytics.setUp(
        webinarId,
        this.adId > -1 ? this.adId : null,
        miscId,
        null,
        this.globalData.analyticsEventsCaptureEndPoint,
        this.logger
      )
      this.gaAnalytics.setUp(webinarId ?? miscId ?? this.adId, this.logger)

      var eventV2: AwEventStoreV2 = {
        eventType: AwEventType.WidgetViewed,
        type: this.videoType,
        momentId: this.videoInfo.moment_id ? parseInt(this.videoInfo.moment_id) : undefined
      }
      this.analytics.register(eventV2, null)
    })
  }
})
</script>
<style scoped>
.amplily-player {
  width: 100% !important;
}
#redirect_btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  position: static;
  width: fit-content;
  height: fit-content;
  margin-left: 50%;
  transform: translateX(-50%);
  margin-top: 10px;
  background: var(--theme-color-8);
  border-radius: 4px;
  text-decoration: none;
}
#redirect_btn:hover {
  background: var(--theme-color);
}
#redirect_btn > a {
  color: var(--theme-contrast);
  text-decoration: inherit;
  font-family: var(--font-family);
  position: static;
  left: 9.88%;
  right: 18.11%;
  top: 20%;
  bottom: 20%;

  /* Button */
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: normal;
}
#redirect_btn svg {
  margin-left: 4px;
}
#redirect_btn .contrast {
  fill: var(--theme-contrast);
}
</style>
