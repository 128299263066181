export default {
  "Full Video": "Pełne Wideo",
  "Related Resources": "Powiązane Zasoby",
  Blog: "Blog",
  Play: "Odtwórz",
  "No Captions": "Brak Podpisów",
  Captions: "Napisy",
  "Toggle fullscreen": "Przełącz Pełny Ekran",
  "playback speed": "Prędkość Odtwarzania",
  "Play with captions in": "Odtwórz z napisami w",
  Seek: "Szukaj",
  "Watch Trailer": "Obejrzyj Zwiastun",
  "Watch full video": "Obejrzyj Pełne Wideo",
  "Watch Video": "Obejrzyj Wideo",
  Speakers: "Prelegenci",
  Infographic: "Infografika",
  eBook: "e-Książka",
  Webinar: "Webinar",
  Video: "Wideo",
  "White Paper": "Biała Księga",
  Slides: "Slajdy",
  Article: "Artykuł",
  "Icon not found exception": "Błąd: Ikona Nie Znaleziona",
  "Key Moments": "Kluczowe Momenty",
  Resources: "Zasoby"
}
