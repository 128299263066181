import "reflect-metadata"
import { App, createApp, h, provide, Ref, ref } from "vue"
import { AwOptions } from "../models/AwOptions"
import { addMetaTag } from "@/services/addMetaTag"
import DisableAnalytics from "@/services/isPreview"
import logger from "../logLevel"
import { WebinarDataService } from "@/services/WebinarDataServices"
import { container } from "tsyringe"
import { registerAnalyticsService, registerWebinarDataService, registerGoogleAnalyticsService } from "@/tsyringe.config"
import IGlobalData, { GlobalData } from "@/models/GlobalData"
import { EventBusKey, GlobalDataKey, LoggerKey, translationKey } from "@/symbols"
import eventBus from "@/event-bus"
import { getDataPath } from "@/utils/getDataPath"
import { MAConfig } from "@/services/MarketingAutomationApi"
import { v4 as uuid } from "uuid"
import internationalization from "@/utils/internationalization"
import { setUnmountListener } from "@/services/AppManager"
export function createWebinarVueApp(
  widgetLayout: any,
  options: AwOptions | undefined,
  el: HTMLElement,
  shouldEnableAnalytics: boolean = true
): App<Element> {
  const globalData: Ref<IGlobalData> = ref(new GlobalData())
  const webinarAlternateId = el.getAttribute("data-w-id")
  const isWebinar = !!webinarAlternateId
  const videoid = !isWebinar ? el.getAttribute("data-v-id") : null
  const hubid = !isWebinar && !videoid ? el.getAttribute("data-h-id") : null
  if (isWebinar && options) {
    options.webinarData = null
  }

  const app = createApp({
    components: {
      widgetLayout
    },
    render() {
      return h(widgetLayout, { fetchId: webinarAlternateId ?? videoid ?? hubid })
    },
    created() {
      addMetaTag()
      var dataEndpoint = getDataEndpoint(el, options)
      if (dataEndpoint) globalData.value.PublishedWebinarDataEndPoint = dataEndpoint + "/"
      var apiEndpoint = getApiEndpoint(el, options)
      if (apiEndpoint) globalData.value.baseurlAdminAppUrl = apiEndpoint + "/"
      globalData.value.redirect_data.url = getRedirectUrl(el, options)
      globalData.value.redirect_data.text = getRedirectText(el, options)
      globalData.value.queryEndpoint = getQueryEndpoint(el, options)
      if (options) {
        globalData.value.height = options.height ?? 0
        globalData.value.autoPlay = options.autoPlay ?? false
        globalData.value.startTime = options.startTime ?? 0
      }
      globalData.value.analyticsEventsCaptureEndPoint = `${globalData.value.baseurlAdminAppUrl}event`
      globalData.value.socialShareUrl = globalData.value.isLocalhost
        ? globalData.value.localbase
        : "https://parmonic.ai/videos/"
      MAConfig.baseUrl = globalData.value.baseurlAdminAppUrl
      globalData.value.instanceId = uuid()

      // analytics are enabled if the following conditions are true:
      // 1. the widget is NOT hosted on a product preview page
      // 2. custom webinarData was passed to the widget
      // 3. the widget is running in a non-production context
      if (shouldEnableAnalytics) {
        const shouldNotCollectAnalytics =
          !!options?.webinarData || DisableAnalytics() || process.env.NODE_ENV != "production"
        registerAnalyticsService(shouldNotCollectAnalytics)
        //Register GoogleAnalyticsService
        registerGoogleAnalyticsService(!shouldNotCollectAnalytics)
      }

      // Register the dataservice to fetch data from the API if no options were passed
      if (options?.webinarData) {
        registerWebinarDataService(true)
        const dataService = container.resolve(WebinarDataService)
        dataService.setUp("undefined", "undefined", options.webinarData)
      } else {
        registerWebinarDataService(false)

        let publishedDataEndpoint = globalData.value.PublishedWebinarDataEndPoint
        if (!!videoid) {
          publishedDataEndpoint += getDataPath(videoid)
        }
        const dataService = container.resolve(WebinarDataService)
        dataService.setUp(publishedDataEndpoint, globalData.value.queryEndpoint, {})
      }

      const translation = internationalization()
      //provide dependencies to be injected later
      provide(GlobalDataKey, globalData)
      provide(LoggerKey, logger)
      provide(EventBusKey, eventBus)
      provide(translationKey, translation)

      setUnmountListener(el, app)
    }
  })
  app.config.globalProperties.window = window
  app.mount(el!)
  return app
}

function getDataEndpoint(el: HTMLElement, options: AwOptions | undefined): string | undefined {
  return el.getAttribute("data-endpoint") ?? options?.dataEndpoint
}
function getApiEndpoint(el: HTMLElement, options: AwOptions | undefined): string | undefined {
  return el.getAttribute("data-api-endpoint") ?? options?.dataEndpoint
}
function getRedirectUrl(el: HTMLElement, options: AwOptions | undefined): string | null {
  return el.getAttribute("data-redirect-url") ?? options?.redirect_data?.url ?? null
}
function getRedirectText(el: HTMLElement, options: AwOptions | undefined): string | null {
  return el.getAttribute("data-redirect-text") ?? options?.redirect_data?.text ?? null
}
function getQueryEndpoint(el: HTMLElement, options: AwOptions | undefined): string {
  return el.getAttribute("data-query-endpoint") ?? options?.queryEndpoint ?? ""
}
