import { FullVideoData } from "@/models/FullVideoData"
import { ThemeData } from "./ThemeData"

export default interface IGlobalData {
  baseurlAdminAppUrl: string
  isLocalhost: boolean
  localbase: string
  analyticsEventsCaptureEndPoint: string
  socialShareUrl: string
  PublishedWebinarDataEndPoint: string
  isGated: boolean
  queryEndpoint: string
  theme_data: ThemeData
  eventElement: HTMLElement | undefined
  autoPlay: boolean
  fullVideoInfo: FullVideoData
  redirect_data: { url: string | null; text: string | null }
  height: number
  instanceId: string | undefined
  startTime: number | undefined
}
export class GlobalData implements IGlobalData {
  baseurlAdminAppUrl = "https://a7s.parmonic.ai/"
  isLocalhost = window.location.host.indexOf("localhost") > -1
  localbase = `${window.location.protocol}//${window.location.host.replace("8080", "3000")}/`
  analyticsEventsCaptureEndPoint = ""
  socialShareUrl = ""
  PublishedWebinarDataEndPoint = "https://wapi.parmonic.ai/"
  isGated = false
  queryEndpoint = ""
  theme_data = {
    font_color: "#000000",
    font_family: "Arial, Helvetica, sans-serif",
    font_size: 11,
    theme_color: "#7E24B2"
  }
  time_data = {
    startTime: undefined,
    endTime: undefined,
    screenshotTime: undefined
  }
  eventElement = undefined
  autoPlay = false
  startTime = 0
  fullVideoInfo = new FullVideoData(undefined, undefined)
  redirect_data = { url: null, text: null }
  height = 0
  instanceId = undefined
}
