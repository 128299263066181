<template>
  <div
    :class="dimentionHandlerObj.containerClassList.value"
    @mouseenter="showControls = true"
    @mouseleave="showControls = isplaying ? false : true"
    ref="playerContainer"
    :style="{
      width: '100% !important',
      minHeight: dimentionHandlerObj.height.value + 'px !important'
    }"
  >
    <CtaOverlayComponent
      v-if="cta"
      :isPosterVisible="getisPosterVisible()"
      :cta="cta"
      :currentTime="durationObj.currentTime"
      :dur="durationObj.dur"
      :continuefromTime="video_type == videoType ? continuefromTime : 0"
      :hasEnded="getHasEnded()"
    />
    <LogoOverlayComponent
      v-if="logoOverlay?.url"
      :logoUrl="logoOverlay?.url"
      :colorScheme="logoOverlay?.color_scheme"
      :cta="!!cta"
      :isPosterVisible="getisPosterVisible()"
      :dur="durationObj.dur"
      :duration="duration"
      :continuefromTime="continuefromTime"
      :hasEnded="getHasEnded()"
    />
    <DemoOverlayComponent :useDemoOverlay="useDemoOverlay" />
    <VideoLoadingComponent v-if="isLoading && usetheaterMode == false" :isLoadingFullVideo="isFullVideoLoading" />
    <ContinuePlayComponent
      :continuefromTime="video_type == videoType ? continuefromTime : 0"
      :dur="durationObj.dur"
      :updateWithFullVideo="updateWithFullVideo"
      :hasEnded="getHasEnded()"
      v-if="usetheaterMode == false && post_play == Postplay.CONTINUE_PLAY"
    />
    <CtaContinueButton
      :dur="durationObj.dur"
      :hasEnded="getHasEnded()"
      :cta="cta"
      v-if="!(video_type == videoType ? continuefromTime : 0) && !!cta"
    />
    <VideoErrorComponent
      :videoUrls="getVideoUrls()"
      :videoPlaybackError="getvideoPlaybackError()"
      v-if="usetheaterMode == false"
    />
    <PlayButtonComponent
      :play="play"
      :isPosterVisible="getisPosterVisible()"
      :totalDuration="durationObj.totalDurationString.value"
      :isTrailer="isTrailer"
      :title="title ?? videoType"
      v-if="!isMALoading && !autoPlay"
    />
    <LoadingIcon v-if="(isMALoading || !!autoPlay) && isPosterVisible == true" />
    <PosterCaptionsMenuComponent
      :isPosterVisible="getisPosterVisible()"
      :clickCaption="clickCaption"
      :subtitleArray="captionObj.subtitleArray"
      v-if="!autoPlay"
    />
    <PosterComponent
      :isPosterVisible="getisPosterVisible()"
      :poster_url="videoUrls.poster_url"
      :poster_urls="videoUrls.poster_urls"
      :play="play"
    />
    <div class="pw-player-controls player-adon" v-if="usetheaterMode == false">
      <div
        class="player-bottom-container"
        ref="playerBottomController"
        :style="{
          display: showControls ? 'flex' : 'none' + `!important`,
          visibility: !isPosterVisible ? 'visible' : 'hidden' + ' !important'
        }"
      >
        <SeekbarComponent
          :durationString="durationObj.durationString"
          :seekbarCss="seekbarObj.seekbarCss"
          :totalDurationString="durationObj.totalDurationString"
          :play="play"
          :pause="pause"
          :currentTime="durationObj.currentTime"
          :dur="durationObj.dur"
          :video="getVideo()"
          :IS_IOS="IS_IOS"
        />
        <div class="flex-box">
          <ControlsLeftComponent
            :playbtnString="getPlayButtonStringRef()"
            :togglePlay="togglePlay"
            :isPlaying="getIsPlaying()"
            :mediaElm="getVideo()"
            :IS_IOS="IS_IOS"
          />
          <div style="flex-grow: 1 !important"></div>
          <ControlsRightComponent
            :canUseCaptions="getcanUseCaptions()"
            :playerContainer="getplayerContainer()"
            :captionBtnString="captionObj.captionBtnString"
            :IS_IOS="IS_IOS"
            :clickCaption="clickCaption"
            :subtitleArray="captionObj.subtitleArray"
            :currentCaptions="captionObj.currentCaptions"
            :height="dimentionHandlerObj.height"
            :videoPlayer="getVideo()"
          />
        </div>
      </div>
      <CaptionsComponent
        :captionData="captionObj.currentCaptionData"
        :currentTime="durationObj.currentTime"
        v-if="canUseCaptions"
      />
    </div>
    <LightboxPosterComponent
      ref="lightboxPoster"
      :poster_url="videoUrls.poster_url"
      :poster_urls="videoUrls.poster_urls"
      v-if="usetheaterMode == true"
    />
    <div class="pw-player-container" ref="shaka_container" v-else>
      <video ref="video" preload="metadata" class="pw-video" crossorigin @click="isplaying ? pause() : play()">
        <track
          v-for="(track, i) in subtitlesForIos"
          :key="i"
          :src="track.web_vtt_url"
          :label="getLanguageString(track.language)"
          kind="subtitles"
          :srclang="track.language"
          :default="track.language == 'en' ? true : false"
          crossorigin
        />
      </video>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import "../../assets/video/shaka.css";
@import "../../assets/video/player-poster.css";
@import "../../assets/video/shaka-play-button.css";
@import "../../assets/video/seek-bar.css";
@import "../../assets/volume_slider.css";
@import "../../assets/video/continue-play.css";
video::-webkit-media-controls {
  display: none;
}
</style>

<script lang="ts">
//always import shaka.css first [coz it has default values rest of them overwrite when needed]
import { SubtitleUrls } from "@/models/captions/SubtitleUrls"
import { VideoInfo } from "@/models/VideoInfo"
import { computed, defineComponent, onBeforeUnmount, PropType, ref, SetupContext, watch } from "vue"
import setUpShaka from "@/composables/shaka-player/load-shaka"
import injectStrict from "@/utils/injectStrict"
import { EventBusKey, GlobalDataKey, LoggerKey } from "@/symbols"
import { isIOS, isMobile } from "@/services/deviceDetection"
import isVideoUrlsValid from "@/utils/VideoUrlsValidator"
import setUpDuration from "@/composables/shared/duration-handler"
import { StringToSeconds } from "@/services/DurationFormatter"
import { setUpPlayBtn } from "@/composables/shared/play-button-handler"
import setUpSeekBar from "@/composables/shared/seekbar-handler"
import mergeSubtitles from "@/services/mergeSubtitles"
import { AwEventType } from "@/models/AwEventType"
import { container } from "tsyringe"
import { AnalyticsClient } from "@/services/AnalyticsService"
import { ContentType } from "@/models/ContentType"
import { VideoCommandType } from "@/models/VideoCommandType"
import SetUpCaptionService from "@/composables/captions-handler"
import setupShortcutKeys from "@/composables/shared/shortcut-handler"
import { getLanguageString } from "@/utils/getLanguageString"
import setUpDinemtionHandler from "@/composables/shared/dimention-handler"
import LogoOverlayData from "@/models/LogoOverlayData"
import setUpVideoManager from "@/services/videoPlayerManager"
import { GoogleAnalyticsClient } from "@/services/GoogleAnalyticsService"

//Components
import CaptionsComponent from "@/components/VideoPlayerComponents/CaptionsComponent.vue"
import ContinuePlayComponent from "@/components/VideoPlayerComponents/ContinuePlayComponent.vue"
import VideoErrorComponent from "@/components/VideoPlayerComponents/VideoErrorComponent.vue"
import PlayButtonComponent from "@/components/VideoPlayerComponents/PlayButtonComponent.vue"
import PosterCaptionsMenuComponent from "@/components/VideoPlayerComponents/PosterCaptionsMenuComponent.vue"
import SeekbarComponent from "@/components/VideoPlayerComponents/SeekbarComponent.vue"
import ControlsLeftComponent from "@/components/VideoPlayerComponents/ControlsLeftComponent.vue"
import ControlsRightComponent from "@/components/VideoPlayerComponents/ControlsRightComponent.vue"
import PosterComponent from "@/components/VideoPlayerComponents/PosterComponent.vue"
import DemoOverlayComponent from "@/components/VideoPlayerComponents/DemoOverlayComponent.vue"
import LogoOverlayComponent from "@/components/VideoPlayerComponents/LogoOverlayComponent.vue"
import LightboxPosterComponent from "@/components/VideoPlayerComponents/LightboxPosterComponent.vue"
import VideoLoadingComponent from "@/components/VideoPlayerComponents/VideoLoadingComponent.vue"
import ScriptComponent from "@/components/MA/ScriptComponent.vue"
import MAFormModal from "@/components/MA/MAFormModal.vue"
import { gateCheckIsKnown } from "@/services/MarketingAutomationApi"
import { MAData, PlayerCallback } from "@/models/ma/MAData"
import LoadingIcon from "../VideoPlayerComponents/LoadingIcon.vue"
import GaEvent from "@/models/googleAnalytics/GaEvent"
import { LightboxEventBusModal } from "@/models/lightbox/LightboxModels"
import { AwEventStoreV2 } from "@/models/AwEventStoreV2"
import { GateType } from "@/models/ma/GateType"
import CtaOverlayComponent from "@/components/VideoPlayerComponents/CtaOverlayComponent.vue"
import { RelatedResourceData } from "@/models/RelatedResourceData"
import CtaContinueButton from "../VideoPlayerComponents/CtaContinueButton.vue"
import eventBus from "@/event-bus"
import { Postplay } from "@/models/PostPlayOptions"
import { LanguageList } from "@/models/LanguageList"
export default defineComponent({
  components: {
    CaptionsComponent,
    ContinuePlayComponent,
    VideoErrorComponent,
    PlayButtonComponent,
    PosterCaptionsMenuComponent,
    SeekbarComponent,
    ControlsLeftComponent,
    ControlsRightComponent,
    PosterComponent,
    DemoOverlayComponent,
    LogoOverlayComponent,
    VideoLoadingComponent,
    ScriptComponent,
    MAFormModal,
    LoadingIcon,
    LightboxPosterComponent,
    CtaOverlayComponent,
    CtaContinueButton
  },
  props: {
    video_urls: { type: Object as PropType<VideoInfo>, required: true },
    duration: String,
    subtitleUrls: Object as PropType<SubtitleUrls>,
    subtitles: Array as PropType<Array<SubtitleUrls>>,
    autoloadSubtitle: { type: Object as PropType<LanguageList>, default: null, required: false },
    isgated: { type: Boolean, default: false, required: false },
    mid: { type: Number, required: false },
    videoType: { type: String, required: true },
    continuefromTime: { type: Number, default: 0, required: false },
    isTrailer: { type: Boolean, required: false, default: false },
    useDemoOverlay: { type: Boolean, required: false, default: false },
    title: { type: String, required: true },
    logoOverlay: { type: Object as PropType<LogoOverlayData>, required: false, default: null },
    height: { type: Number, default: null, required: false },
    ma: { type: Object as PropType<MAData>, required: false },
    usetheaterMode: { type: Boolean, required: false, default: false },
    autoPlay: { type: Boolean, required: false, default: false },
    currentSubtitle: { type: String, required: false },
    cta: { type: Object as PropType<RelatedResourceData>, default: null },
    post_play: { type: String, required: false, default: undefined },
    webinarId: { type: Number, required: false, default: null },
    isLightboxVideo: { type: Boolean, required: false, default: false }
  },
  setup(props) {
    const IS_IOS = isIOS(navigator.userAgent, navigator.maxTouchPoints)
    const IS_MOBILE = isMobile(navigator.userAgent, navigator.maxTouchPoints)
    let captionBtn = ref<HTMLElement>()
    let playerBottomController = ref<HTMLElement>()
    let playerContainer = ref<HTMLElement>()
    let lightboxPoster = ref<HTMLElement>()
    let videoUrls = ref(props.video_urls)
    let getVideoUrls = () => videoUrls
    let logger = injectStrict(LoggerKey)
    var showControls = ref(true)
    var enterframeTimeout = 0
    var buffercheckTimeout = 0
    const globalData = injectStrict(GlobalDataKey)
    const video = ref<HTMLVideoElement>()
    let shakaSeeker = ref<HTMLInputElement>()
    const isPosterVisible = ref(true)
    let getisPosterVisible = () => isPosterVisible
    let durationObj = setUpDuration(StringToSeconds(props.duration))
    const captionObj = SetUpCaptionService(mergeSubtitles(props.subtitleUrls, props.subtitles))
    const videoPlaybackError = ref(false)
    let shakaSetUpObj = setUpShaka(video, videoPlaybackError, false)
    let hasEnded = ref(false)
    let { isplaying, playbtnString, getPlayButtonStringRef } = setUpPlayBtn(hasEnded)
    let seekbarObj = setUpSeekBar(durationObj.currentTime, durationObj.dur)
    var subtitlesForIos = computed(() => (IS_IOS ? captionObj.subtitleArray.value : []))
    const captionDropDownHeight = computed(() =>
      captionObj.subtitleArray.value.length > 2 ? 3 * 27 + 40 : (captionObj.subtitleArray.value.length + 1) * 27 + 40
    )
    let eventbus = injectStrict(EventBusKey)
    let startTime = ref(0)
    let analytics = container.resolve(AnalyticsClient)
    const gaAnalytics = container.resolve(GoogleAnalyticsClient)
    const video_type = computed(() =>
      durationObj.dur.value == StringToSeconds(props.duration) ? props.videoType : ContentType.Webinar
    )
    let getHasEnded = () => hasEnded
    let canUseCaptions = computed(() => captionObj.subtitleArray.value.length > 0 && !IS_IOS)
    let getVideo = () => video
    let getcanUseCaptions = () => canUseCaptions
    let getplayerContainer = () => playerContainer
    let getvideoPlaybackError = () => videoPlaybackError
    let getIsPlaying = () => isplaying
    let dimentionHandlerObj = setUpDinemtionHandler(playerContainer)
    let videoManager = setUpVideoManager()
    let isMALoading = ref<boolean>(false)
    let isLoading = computed(() => {
      if (seekbarObj.totalBufferedTime.value == 0) return false
      if (video.value != null && video.value.duration - video.value.currentTime < 1) return false
      return seekbarObj.totalBufferedTime.value < durationObj.currentTime.value + 0.5
    })
    let isFullVideoLoading = ref<boolean>(false)
    let percentMarkersForGa = [10, 25, 50, 75]
    if (!IS_MOBILE) setupShortcutKeys(video, isplaying)
    let IsGated = computed<boolean>(() => {
      if (!props.isgated || !props.ma) return false
      var gate_after = props.ma.gate_after ?? 0
      if (durationObj.currentTime.value >= gate_after || gate_after == 0 || IS_IOS) {
        if (!props.ma.gating_preference || props.ma?.gating_preference == GateType.All) return true
        if (props.videoType == ContentType.Webinar && props.ma?.gating_preference == GateType.Webinar) return true
      }
      return false
    })

    watch(hasEnded, newVal => {
      if (newVal && props.post_play == Postplay.AUTO_PLAY) {
        eventBus.emit(Postplay.AUTO_PLAY, { mid: props.mid })
      }
    })

    const isLastMoment = ref<boolean>(false)
    if (props.autoloadSubtitle) captionObj.setCaption(props.autoloadSubtitle)
    return {
      IsGated,
      lightboxPoster,
      percentMarkersForGa,
      gaAnalytics,
      buffercheckTimeout,
      isFullVideoLoading,
      isMALoading,
      isLoading,
      dimentionHandlerObj,
      getplayerContainer,
      getcanUseCaptions,
      getVideo,
      getPlayButtonStringRef,
      getLanguageString,
      IS_MOBILE,
      IS_IOS,
      canUseCaptions,
      getisPosterVisible,
      getVideoUrls,
      getHasEnded,
      getvideoPlaybackError,
      getIsPlaying,
      durationObj,
      seekbarObj,
      captionObj,
      hasEnded,
      captionBtn,
      analytics,
      startTime,
      eventbus,
      globalData,
      subtitlesForIos,
      captionDropDownHeight,
      showControls,
      video,
      playerBottomController,
      shakaSetUpObj,
      isPosterVisible,
      shakaSeeker,
      isplaying,
      playbtnString,
      enterframeTimeout,
      playerContainer,
      videoUrls,
      video_type,
      logger,
      videoPlaybackError,
      videoManager,
      Postplay,
      isLastMoment
    }
  },
  async mounted() {
    if (!isVideoUrlsValid(this.videoUrls, this.logger)) return
    if (this.lightboxPoster) {
      this.dimentionHandlerObj.handleResize(this.height ?? this.lightboxPoster.clientHeight)
      window.addEventListener("resize", () =>
        this.dimentionHandlerObj.handleResize(this.lightboxPoster?.clientHeight ?? this.height)
      )
    }
    if (!this.video) return
    this.videoManager.addPlayer(this.video)
    var self = this
    this.dimentionHandlerObj.handleResize(this.height ?? this.video.clientHeight)
    window.addEventListener("resize", () => this.dimentionHandlerObj.handleResize(this.video?.clientHeight ?? this.height))

    this.setupEvents()
    this.buffercheckTimeout = setInterval(() => this.bufferCheck(), 500)

    this.video.oncanplay = () => {
      this.dimentionHandlerObj.setPlayerHeight(this.playerContainer?.clientHeight ?? this.height)
      if (this.autoPlay == true && this.video) this.clickCaption(this.currentSubtitle, true)
      setTimeout(() => {
        if (this.video?.clientHeight) this.eventbus.emit("setHeight", this.dimentionHandlerObj.height.value)
      }, 1000)
    }
    this.video.onplaying = () => {
      this.isFullVideoLoading = false
    }

    this.video.onended = function () {
      self.redirect()
      self.hasEnded = true
      clearTimeout(self.enterframeTimeout)
    }
    if (!window.momentList) window.momentList = []
    const isMomentExist = window.momentList.findIndex(m => m.mid == this.mid && m.instanceId == this.globalData.instanceId)
    const autoPlayMoment = () => {
      if (this.video) this.video.currentTime = 0
      this.play()
    }
    if (isMomentExist === -1)
      window.momentList.push({ mid: this.mid, play: autoPlayMoment, instanceId: this.globalData.instanceId })
    else window.momentList[isMomentExist].play = autoPlayMoment
    await this.loadPlayer()
    this.dimentionHandlerObj.handleResize(this.height ?? this.video?.clientHeight)
    if (this.globalData.autoPlay) this.play()
    //This will only happen if the video is a single or shared
    if (this.globalData.startTime) this.video.currentTime = this.globalData.startTime

    this.isLastMoment =
      window.momentList
        .filter(m => m.mid != null)
        ?.findIndex(m => m.mid == this.mid && m.instanceId == this.globalData.instanceId) ==
      window.momentList?.filter(m => m.mid != null).length - 1
  },
  methods: {
    async loadPlayer() {
      if (this.IS_IOS) {
        await this.shakaSetUpObj.loadhls(this.videoUrls.hls_url!)
      } else {
        await this.shakaSetUpObj.loaddash(this.videoUrls.dash_url!)
      }
    },
    onplay() {
      window.parmonicFocusedVideo = {
        togglePlay: this.togglePlay,
        pause: this.pause,
        isPlaying: this.getIsPlaying()
      }
      this.startTime = this.durationObj.currentTime.value
      this.isplaying = true
      this.hasEnded = false
      this.isPosterVisible = false
      this.enterframeTimeout = setInterval(() => this.enterframe(), 200)
      if (this.startTime == 0) this.registerAnalytics(null)
    },
    onpause() {
      this.isplaying = false
      this.showControls = true
      clearTimeout(this.enterframeTimeout)
      this.registerAnalytics(this.video?.currentTime ?? null)
    },
    async togglePlay() {
      this.isplaying ? this.pause() : await this.play()
    },
    setupEvents() {
      if (!this.video) return
      this.video.addEventListener("play", this.onplay)
      this.video.addEventListener("pause", this.onpause)
    },
    async play() {
      this.setupEvents()
      if (this.IsGated && !(await this.isknown())) return
      if (this.usetheaterMode) {
        this.clickCaption(null, true)
      }
      this.video?.play()
      this.videoManager.pauseAll(this.video)
    },
    async isknown(): Promise<boolean> {
      try {
        if (window.parmonic_form_shown == true) return true
        this.isMALoading = true
        if (window.parmonic_always_show_form == true || !(await gateCheckIsKnown(this.ma))) {
          this.sendPageAnalytics(AwEventType.FormViewed)
          this.eventbus.emit("gated-play", {
            instanceId: this.globalData.instanceId,
            callback: async () => {
              this.sendPageAnalytics(AwEventType.FormSubmitted)
              window.parmonic_form_shown = true
              await this.play()
            }
          } as PlayerCallback)
          return false
        } else {
          return true
        }
      } finally {
        this.isMALoading = false
      }
    },
    pause() {
      this.setupEvents()
      this.video?.pause()
    },
    async enterframe(registerAnalytics: boolean = true) {
      if (!this.video) return
      if (this.IsGated && !(await this.isknown())) {
        this.pause()
        this.eventbus.emit("remove-fullscreen")
      }
      this.durationObj.currentTime.value = this.video.currentTime
      if (this.durationObj.currentTime.value - this.startTime > 5 && registerAnalytics == true) {
        this.registerAnalytics(this.video.currentTime)
        this.startTime = this.durationObj.currentTime.value
      }
    },
    bufferCheck() {
      if (!this.video) return
      try {
        this.seekbarObj.totalBufferedTime.value = this.video.buffered.end(0) ?? 0
      } catch (e) {} //log error
    },
    clickCaption(cap: string | undefined | null, play: boolean = false) {
      if (this.usetheaterMode == true) this.calllightbox(cap)
      else {
        if (!this.IS_IOS && cap) this.captionObj.setCaption(cap)
        if (play) this.play()
      }
    },
    calllightbox(cap: string | null | undefined) {
      this.eventbus.emit(
        "setup-lightbox",
        new LightboxEventBusModal(this.globalData.instanceId, this.mid ?? -1, cap, this.videoType)
      )
    },
    redirect() {
      if (this.globalData.redirect_data.url) window.location.href = this.globalData.redirect_data.url
    },
    async updateWithFullVideo() {
      if (!this.globalData.fullVideoInfo.isReady || !this.continuefromTime || !this.video) return
      this.captionObj.updateCaptionsArray(this.globalData.fullVideoInfo.subtitles)
      this.videoUrls = this.globalData.fullVideoInfo.video_urls as VideoInfo
      this.isFullVideoLoading = true
      await this.shakaSetUpObj.detachShakaPlayer()
      await this.loadPlayer()
      this.percentMarkersForGa = [10, 25, 50, 75]
      this.video.currentTime = this.continuefromTime
      this.durationObj.currentTime.value = this.video.currentTime
      this.durationObj.dur.value = this.video.duration
      this.play()
    },
    registerAnalytics(end_time: number | null) {
      var end = end_time ? Math.floor(end_time) : null
      var eventV2 = null
      eventV2 = {
        eventType: AwEventType.VideoPlayed,
        momentId: this.durationObj.dur.value == StringToSeconds(this.duration) && (this.mid ?? 0) > 0 ? this.mid : undefined,
        start: Math.floor(this.startTime),
        end: end,
        type: this.video_type,
        command:
          this.startTime == 0 && end == null
            ? VideoCommandType.Start
            : end == this.durationObj.dur.value
            ? VideoCommandType.End
            : null
      }

      this.analytics.register(eventV2, this.webinarId)

      if (eventV2.command == VideoCommandType.Start) {
        this.gaAnalytics.registerVideoStart({
          item_id:
            this.durationObj.dur.value == StringToSeconds(this.duration) && (this.mid ?? 0) > 0 ? this.mid : this.webinarId,
          content_type: this.video_type,
          video_title: this.title
        } as GaEvent)
      } else if (eventV2.command == VideoCommandType.End) {
        this.gaAnalytics.registerVideoComplete({
          item_id:
            this.durationObj.dur.value == StringToSeconds(this.duration) && (this.mid ?? 0) > 0 ? this.mid : this.webinarId,
          content_type: this.video_type,
          video_title: this.title
        } as GaEvent)
      }
      if (this.video && this.percentMarkersForGa.length > 0) {
        var percent = (this.video.currentTime * 100) / this.video.duration
        var lostMarkers = this.percentMarkersForGa.filter(x => x < percent)
        if (this.percentMarkersForGa.filter(x => x > percent).length != this.percentMarkersForGa.length) {
          this.gaAnalytics.registerVideoProgress({
            item_id:
              this.durationObj.dur.value == StringToSeconds(this.duration) && (this.mid ?? 0) > 0
                ? this.mid
                : this.webinarId,
            content_type: this.video_type,
            video_title: this.title,
            video_percent: lostMarkers[lostMarkers.length - 1]
          } as GaEvent)
          this.percentMarkersForGa = this.percentMarkersForGa.filter(x => x > percent)
        }
      }
    },
    sendPageAnalytics(eventType: AwEventType) {
      let eventV2: AwEventStoreV2 = {
        eventType: eventType,
        momentId: this.durationObj.dur.value == StringToSeconds(this.duration) && (this.mid ?? 0) > 0 ? this.mid : undefined,
        type: this.videoType
      }
      this.analytics.register(eventV2, this.webinarId)
    }
  },
  async beforeUnmount() {
    clearInterval(this.buffercheckTimeout)
    if (this.isplaying) this.pause()
    await this.shakaSetUpObj.detachShakaPlayer()
  }
})
</script>
