export default {
  "Full Video": "Tam Video",
  "Related Resources": "İlgili Kaynaklar",
  Blog: "Blog",
  Play: "Oynat",
  "No Captions": "Altyazı Yok",
  Captions: "Altyazılar",
  "Toggle fullscreen": "Tam Ekran Geçişi",
  "playback speed": "Oynatma Hızı",
  "Play with captions in": "Altyazılarla Oynat",
  Seek: "Ara",
  "Watch Trailer": "Fragmanı İzle",
  "Watch full video": "Tam Videoyu İzle",
  "Watch Video": "Videoyu İzle",
  Speakers: "Konuşmacılar",
  Infographic: "Bilgilendirici Grafik",
  eBook: "e-Kitap",
  Webinar: "Web Semineri",
  Video: "Video",
  "White Paper": "Beyaz Kitap",
  Slides: "Slaytlar",
  Article: "Makale",
  "Icon not found exception": "Simge Bulunamadı Hatası",
  "Key Moments": "Önemli Anlar",
  Resources: "Kaynaklar"
}
